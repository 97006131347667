<template>
  <v-breadcrumbs :items="breadcrumbItems">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :disabled="disabled(item)"
        @click="onSelectedBreadcrumbItem(item)"
      >
        <template v-if="disabled(item)">
          <span>
            <v-icon>{{ breadcrumbIconName(item) }}</v-icon>
            {{ item.text }}
          </span>
        </template>
        <template v-else>
          <router-link
            :to="buildTargetRoute(item.id)"
            class="v-breadcrumbs__item"
          >
            <span :class="breadcrumbTextClass">
              <v-icon :color="breadcrumbIconColorClass(item)">{{
                breadcrumbIconName(item)
              }}</v-icon>
              {{ breadcrumbText(item) }}
            </span>
          </router-link>
        </template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { fontEmphasis, Text } from "@/design/text/Text";

// mixins
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";

/**
 * BreadcrumbsRecord component
 */
export default {
  name: "BreadcrumbsRecord",
  mixins: [recordIconMixin],
  props: {
    /**
     * @type {Array<{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}>}
     */
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    /**
     * build Target Route Function
     */
    buildTargetRoute: {
      type: Function,
      required: false,
      /**
       * buildTargetRoute default
       * @param {Number|number} id record id
       * @return {{name: (String|string), params: {id:number}}}
       */
      // eslint-disable-next-line no-unused-vars
      default(id) {
        return undefined;
      }
    },
    /**
     * can Navigate to specified Target Route
     */
    canNavigate: {
      type: Function,
      required: false,
      /**
       * can Navigate to specified Target Route
       * @param {Number|number} id record id
       * @return {boolean} true when can navigate to specified Target Route
       */
      // eslint-disable-next-line no-unused-vars
      default(id) {
        return false;
      }
    },
    selectedRecord: undefined
  },
  computed: {
    /**
     * get computed breadcrumb Items
     * @return {Array<{id: number, disabled: boolean, text: string}>} Array of breadcrumb Items
     */
    breadcrumbItems() {
      let items = this.breadcrumbs.map(bc => {
        return {
          id: bc.id,
          text: bc.name,
          disabled: false
        };
      });

      // Check if selectedRecord is defined and not already in the items array
      if (
        this.selectedRecord &&
        !items.some(item => item.id === this.selectedRecord.id)
      ) {
        items.push({
          id: this.selectedRecord.id,
          text: this.selectedRecord.name,
          disabled: true
        });
      }

      // Ensure the last item in the items array is disabled
      if (items.length > 0) {
        items[items.length - 1].disabled = true;
      }
      return items;
    },

    /**
     * breadcrumb Text Class
     * @return {string}
     */
    breadcrumbTextClass() {
      const color = new Color(
        colorMD.indigo,
        variantType.lighten,
        variantNumber.n1
      );
      return new Text(color, undefined, fontEmphasis.bold).getClassText();
    }
  },
  methods: {
    /**
     * breadcrumb Icon Name
     * @param {{id: number, disabled: boolean, text: string}}  breadcrumbItem breadcrumb Item
     * @return {string|undefined}
     */
    breadcrumbIconName(breadcrumbItem) {
      if (this.selectedRecord) {
        if ((breadcrumbItem?.id ?? -1) === this.selectedRecord?.id)
          return this.recordIcon(this.selectedRecord);
      }

      const bc = this.breadcrumbs?.find(
        bc => bc.id === (breadcrumbItem?.id ?? -1)
      );
      return bc ? this.recordIcon(bc) : undefined;
    },

    /**
     * breadcrumb Item text
     * @param {{id: number, disabled: boolean, text: string}}  breadcrumbItem breadcrumb Item
     * @return {string}
     */
    breadcrumbText(breadcrumbItem) {
      return breadcrumbItem?.text ?? "";
    },

    /**
     * breadcrumb Icon Color Class
     * @param {{id: number, disabled: boolean, text: string}} breadcrumbItem breadcrumb Item
     * @return {string|undefined}
     */
    breadcrumbIconColorClass(breadcrumbItem) {
      const bc = this.breadcrumbs?.find(
        bc => bc.id === (breadcrumbItem?.id ?? -1)
      );
      return bc ? this.recordIconColorClass(bc) : undefined;
    },

    /**
     * onSelectedBreadcrumbItem event handler
     * @param {{id: number, disabled: boolean, text: string}} breadcrumbItem breadcrumb Item
     */
    onSelectedBreadcrumbItem(breadcrumbItem) {
      try {
        const bc = this.findBreadcrumb(breadcrumbItem);
        if (bc) {
          this.$emit("selectedBreadcrumb", bc);
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * find Breadcrumb
     * @param {{id: number, disabled: boolean, text: string}}  breadcrumbItem breadcrumb Item
     * @return {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    findBreadcrumb(breadcrumbItem) {
      const id = breadcrumbItem?.id ?? -1;

      return this.breadcrumbs?.find(bc => bc.id === id);
    },

    /**
     * is disabled breadcrumb item
     * @param item
     * @return {Boolean|boolean}
     */
    disabled(item) {
      return this.selectedRecord
        ? item?.disabled ?? true
        : this.canNavigate
        ? !this.canNavigate(item?.id ?? -1) ?? true
        : item?.disabled ?? true;
    }
  }
};
</script>
